import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import RegistrationForm from './RegistrationForm';
import LoginForm from './LoginForm';
import ChatInterface from './ChatInterface';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState('');


    const handleLogin = (userToken) => {
        localStorage.setItem('userToken', userToken);
        setToken(userToken);
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        setToken('');
        setIsAuthenticated(false);
    };

    return (
        <Router>
            <div className='app-container'>
                {/* <nav>
                    {!isAuthenticated && <Link to="/register">Register</Link>}
                    {!isAuthenticated && <Link to="/login">Login</Link>}
                    {isAuthenticated && <Link to="/chat">Chat</Link>}
                </nav> */}

                <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/register" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<LoginForm onLogin={handleLogin} />} />
                    <Route path="/chat" element={isAuthenticated ? <ChatInterface token={token} onLogout={handleLogout} /> : <Navigate replace to="/login" />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
























// // App.js
// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { sendMessage, receiveMessage } from './reducers';
// import Chat from './Chat';
// import { Input, Button } from 'antd';
// // import 'antd/dist/antd.css';
// import './css/App.css'; // Assicurati di avere questo file CSS per gli stili personalizzati di cui parleremo

// function App() {
//   const dispatch = useDispatch();
//   const messages = useSelector((state) => state.messages.value);
//   const [userInput, setUserInput] = useState('');

//   const handleUserInput = (event) => {
//     setUserInput(event.target.value);
//   };

//   const handleSendMessage = () => {
//     dispatch(sendMessage(userInput));

//     // Simulazione di risposta dal chatbot (in attesa di implementazione reale)
//     setTimeout(() => {
//       dispatch(receiveMessage('Risposta dal chatbot'));
//     }, 1000);

//     setUserInput('');
//   };

//   return (
//     <div className="app-container">
//       <Chat messages={messages} />
//       <div className="input-container">
//         <Input
//           placeholder="Inserisci il tuo messaggio"
//           value={userInput}
//           onChange={handleUserInput}
//         />
//         <Button type="primary" onClick={handleSendMessage}>
//           Invia
//         </Button>
//       </div>
//     </div>
//   );
// }

// export default App;
