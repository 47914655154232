import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from './api/axiosConfig';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LoadingOutlined,
  SendOutlined,
  PlusOutlined,
  RobotOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, List, Avatar, Popover, Spin, Modal, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import './css/Chat.css';

const { Header, Sider, Content } = Layout;

function ChatInterface({ token, onLogout }) {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [threads, setThreads] = useState([]); // [ {id: 1, name: "name", user_id: '1} ]
    const [selectedThread, setSelectedThread] = useState(null); // {id: 1, name: "name", user_id: '1'
    const [chatHistory, setChatHistory] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newThreadName, setNewThreadName] = useState('');
    const [selectedThreadIndex, setSelectedThreadIndex] = useState(null);
    const [isWaiting, setIsWaiting] = useState(false);
    const [user, setUser] = useState(null);
    const {
      token: { colorBgContainer },
    } = theme.useToken();
    const messagesEndRef = useRef(null);

    const handleLogout = () => {
        onLogout();
        navigate('/login');
    };

    const content = (
      <div>
        <p>Username: {user ? user.username : ''}</p>
        <Button onClick={handleLogout}>Logout</Button>
      </div>
    );

    const sendMessage = async () => {
        if (!message.trim() || isWaiting) return;

        setIsWaiting(true);
        const newHistory = [...chatHistory, {is_user_message: true, content: message}];
        setChatHistory(newHistory);

        // Add a loading message
        const loadingMessage = {is_user_message: false, content: <Spin indicator={<LoadingOutlined spin />} />, isLoading: true};
        setChatHistory([...newHistory, loadingMessage]);

        setMessage('');

        try {
            // Updated API call to send message
            const response = await axiosInstance.post('/chat', 
                { user_query: message, thread_id: selectedThread.id }
            );

            // Remove the loading message and add the actual response
            setChatHistory(prevHistory => 
                prevHistory.filter(msg => !msg.isLoading)
                    .concat({is_user_message: false, content: response.data.bot_reply})
            );
        } catch (error) {
            console.error('Error sending message:', error);
            // Remove the loading message and add an error message
            setChatHistory(prevHistory => 
                prevHistory.filter(msg => !msg.isLoading)
                    .concat({is_user_message: false, content: "Sorry, there was an error processing your request."})
            );
        } finally {
            setIsWaiting(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    const scrollToBottom = () => {
      console.log('scrollToBottom');
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(scrollToBottom, [chatHistory]);

    useEffect(() => {
        async function fetchThreads() {
            try {
                const response = await axiosInstance.get('/threads');
                setThreads(response.data);
            } catch (error) {
                console.error('Error fetching threads:', error);
            }
        }
        async function fetchUser() {
            try {
                const response = await axiosInstance.get('/users');
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        }
        fetchThreads();
        fetchUser();
    }, []);

    useEffect(() => {
      console.log('selectedThread', selectedThread);
      if (selectedThread == null) return;
      async function fetch() {
        setLoading(true);
        const response = await axiosInstance.get(`/chat?thread_id=${selectedThread.id}`);
        setChatHistory(response.data);
        console.log('chatHistory', response.data);
        setLoading(false);
      }
      fetch()
    }, [selectedThread]);

    const createThread = async (name) => {
      const response = await axiosInstance.post('/threads', {
        name: name
      });
      const newThreads = [...threads, response.data];
      setThreads(newThreads);
      setSelectedThread(newThreads[newThreads.length - 1]);
      setSelectedThreadIndex(newThreads.length - 1);
    };

    const showCreateThreadModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        if (newThreadName.trim()) {
            setLoading(true);
            await createThread(newThreadName.trim());
            setIsModalVisible(false);
            setNewThreadName('');
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setNewThreadName('');
    };

    const handleThreadSelect = (index) => {
        setSelectedThread(threads[index]);
        setSelectedThreadIndex(index);
    };

    // // Update the axiosInstance to use navigate
    // useEffect(() => {
    //     axiosInstance.interceptors.response.use(
    //         (response) => response,
    //         (error) => {
    //             if (error.response && error.response.status === 401) {
    //                 localStorage.removeItem('token');
    //                 onLogout();
    //                 navigate('/login');
    //             }
    //             return Promise.reject(error);
    //         }
    //     );
    // }, [navigate, onLogout]);

    return (
      <Layout>
        <Sider trigger={loading} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={showCreateThreadModal}
            style={{
              margin: '16px',
              width: collapsed ? '80%' : '90%',
              transition: 'width 0.3s',
            }}
          >
            {collapsed ? '' : 'New Thread'}
          </Button>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={selectedThreadIndex !== null ? [selectedThreadIndex.toString()] : []}
            onClick={e => handleThreadSelect(e.key)}
            items={threads.map((thread, index) => ({
              key: index.toString(),
              label: thread.name
            }))}
          />
        </Sider>
        <Layout>
          <Header style={{ padding: 0, background: colorBgContainer, height: '10vh', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
            <h2>{selectedThread ? selectedThread.name : 'No Thread Selected'}</h2>
            <Popover content={content} trigger="click" placement="bottomLeft">
              <Avatar icon={<UserOutlined />} style={{ marginRight: '20px' }} />
            </Popover>
          </Header>
          <Content>
            <Spin spinning={loading} tip="Loading messages..." indicator={<LoadingOutlined spin />}>
              <div className="chat-container">
                <div className="chat-history">
                    <List
                      dataSource={chatHistory}
                      renderItem={(item) => (
                        <List.Item className={`message ${item.is_user_message ? 'user' : 'bot'}`}>
                          <List.Item.Meta
                            avatar={<Avatar icon={item.is_user_message ? <UserOutlined /> : <RobotOutlined />} />}
                            title={item.is_user_message ? 'You' : 'Chatbot'}
                            description={item.content}
                          />
                        </List.Item>
                      )}
                    />
                    <div ref={messagesEndRef} />
                </div>
                <div className="chat-input">
                    <Input
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Type a message..."
                        style={{ flexGrow: 1, marginRight: '10px' }}
                        disabled={isWaiting || selectedThread == null}
                    />
                    <Button
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={sendMessage}
                        style={{ height: '40px' }}
                        disabled={isWaiting || !message.trim() || selectedThread == null}
                    >
                        Send
                    </Button>
                </div>
              </div>
            </Spin>
          </Content>
        </Layout>
        <Modal
            title="Create New Thread"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Input
                placeholder="Enter thread name"
                value={newThreadName}
                onChange={(e) => setNewThreadName(e.target.value)}
            />
        </Modal>
      </Layout>
    );
}

export default ChatInterface;



    // return (
    //     <div className="chat-container">
    //         <div className="chat-history">
    //             <List
    //               dataSource={chatHistory}
    //               renderItem={(item) => (
    //                 <List.Item className={`message ${item.is_user_message ? 'user' : 'bot'}`}>
    //                   <List.Item.Meta
    //                     avatar={<Avatar icon={item.is_user_message ? 'user' : 'robot'} />}
    //                     title={item.is_user_message ? 'Tu' : 'Chatbot'}
    //                     description={item.content}
    //                   />
    //                 </List.Item>
    //               )}
    //   />
    //         </div>
    //         <div className="chat-input">
    //             <input 
    //                 type="text" 
    //                 value={message} 
    //                 onChange={(e) => setMessage(e.target.value)} 
    //                 placeholder="Type a message..."
    //             />
    //             <button onClick={sendMessage}>Send</button>
    //         </div>
    //     </div>
    // );
