import { createSlice } from '@reduxjs/toolkit'

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    value: [],
  },
  reducers: {
    sendMessage: (state, action) => {
      console.log(state.value);
      console.log(action);
      state.value = [...state.value, {text: action.payload, type: 'sent'}]
    },
    receiveMessage: (state, action) => {
      state.value = [...state.value, {text: action.payload}]
    },
  },
})

// Action creators are generated for each case reducer function
export const { sendMessage, receiveMessage } = messagesSlice.actions

export default messagesSlice.reducer