import React, { useEffect } from 'react';
import axiosInstance from './api/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import './css/App.css'; // Import the CSS file

function LoginForm({ onLogin }) {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem('userToken');
        if (storedToken) {
            onLogin(storedToken);
            navigate('/chat');
        }
    }, []);

    const onFinish = async (values) => {
        try {
            const response = await axiosInstance.post('/token', {
                username: values.username,
                password: values.password
            });
            onLogin(response.data.access_token);
            navigate('/chat');
        } catch (error) {
            message.error('Login failed. Please check your credentials.');
        }
    };

    return (
      <div className="form-container">
        <div className="logo-placeholder"></div>
        <h3>RFID Bot - Login</h3>
        <Form
            form={form}
            name="login"
            onFinish={onFinish}
        >
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
            >
                <Input placeholder="Username" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
            >
                <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Login
                </Button>
            </Form.Item>
        </Form>
			</div>
    );
}

export default LoginForm;
