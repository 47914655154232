const config = {
  development: {
    apiBaseURL: 'http://localhost:8000',
  },
  production: {
    apiBaseURL: 'https://rfid.bot/api', // Replace with your actual production API URL
  },
};

const environment = process.env.REACT_APP_ENV || 'development';

export default config[environment];
