import axios from 'axios';
import config from '../config';

const baseURL = config.apiBaseURL;

const axiosInstance = axios.create({
  baseURL,
  timeout: 600000, // You can adjust this value as needed
});

// Add a request interceptor to set the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('userToken'); // Assuming you store the token in localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 && !window.location.pathname.endsWith('/login')) {
      // Clear the token from localStorage
      localStorage.removeItem('userToken');
      // Redirect to login page
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
